import React, { useState } from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Footer, MetaTags, GalleryModal, VideoPlayer } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, orange, darkGrey } from '../styles/colors'

import logo from '../assets/images/logo-watermark.png'
import fullscreenIcon from '../assets/images/fullscreen.svg'

import { rgba } from 'polished';
import { isClient, media } from '../styles/utils';
import { useMount, useSetState } from 'react-use';

const Info = (props) =>  {
    if (!isClient()) return <></>

    const [{data, acf, globalData}, setState] = useSetState({
        data: false,
        acf: false,
        globalData: false,
    });

    const [galleryModalActive, setGalleryModalActive] = useState(false)

    useMount(() => {
        const data = props.data.wordpressInfopages;

        setState({
            data: data,
            acf: JSON.parse(data.acf_json),
            globalData: JSON.parse(props.data.wordpressOptions.acf_json)
        })
    })

    const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe?.match?.(/src="(.+?)"/)?.[1]
		}
	}

    const renderMediaItems = () => {
        if (data?.slug !== 'about') return
        
        return (
            <MediaItems>
                {acf?.media_items_video_embed && (
                    <VideoPlayer
                        video_type={'embed'}
                        video_embed_src={getVideoURL(acf?.media_items_video_embed)}
                        height={200}
                    />
                )}

                {acf?.media_items_image && (
                    <LightboxImage
                        onClick={() => setGalleryModalActive(true)}
                    >
                        <Image
                            image={acf?.media_items_image?.sizes?.medium2}
                        />
                        <FullScreenIcon/>
                    </LightboxImage>
                )}
            </MediaItems>
        )
    }

    return (
        <Layout
            textBox={acf?.text_content}
            textBoxAudio={acf?.text_content_audio}
        >

            {globalData && (
                <MetaTags 
                    title={`Adventures in the Field`}
                    description={globalData?.meta_description_default}
                    url={`https://adventuresinthefield.com`}
                />
            )}

            <Wrapper
                bgImage={acf?.background_image?.sizes?.full_width}
                slug={data?.slug}
            >
                <Container>

                    <Logo 
                        to={'/'}
                    />
                    
                    <Content>
                        <Title>{data?.title}</Title>

                        {renderMediaItems()}
                    
                        <PageText
                            dangerouslySetInnerHTML={{__html: acf?.page_text}}
                        />
                    </Content>   

                    {galleryModalActive && (
                        <ModalImage>
                            <Close
                                onClick={() => setGalleryModalActive(false) && window.scrollTo(0,0)}
                            />
                            <Image 
                                image={acf?.media_items_image?.sizes?.medium2}
                            />
                        </ModalImage>
                    )} 
                </Container>
            </Wrapper>
            <Footer/>
        </Layout>	
    )
	
}
const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh; 

    ${props => {
        if (props.slug == 'about') return css`
            background-size: contain;
            background-position: center top;
            background-color: #f0efea;
        `
    }}
`

const Container = styled.div`
	${container}
	${padding}
    display: flex;
    flex-direction: column;
    align-items: center; 
`

const Image = styled.div`
    ${bgImage};
    background-image: url(${props => props.image});
`
const Icon = styled.div`
    ${bgIcon};
    background-image: url(${props => props.image});
`


const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
`

const Content = styled.div`
    display: flex;
	flex-direction: column;
    max-width: 580px;
    margin: 100px 0;
`

const Title = styled.div`
    font-family: 'LatoWebBold';   
    text-transform: uppercase;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.3em;
    margin-bottom: 56px;
    text-align: center;

    ${media.phone`
        margin-top: 100px;
    `}
`

const PageText = styled.div`
    font-family: 'LatoWeb';
    font-size: 16px;
    line-height: 24px;

    h2, h3 {
        font-family: 'LatoWebBold';   
        color: ${darkGrey};
    }
    h2 {
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0.3em;
        line-height: 30px;
        margin: 30px 0;
    }

    p {
        color: ${darkGrey};
    }

    a {
        color: ${orange};
        border-bottom: 1px solid ${orange};
        ${hoverState}
    }

    strong {
        font-family: 'LatoWebBold';
        font-size: 18px;
    }
`

// Media Items

const MediaItems = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 40px;

    > * {
        flex: 1;
        flex-basis: calc(50% - 10)px;
    }
    
    .video-player {
        overflow: hidden;
        padding-top: 0;
        background: black;
    }

    ${media.phone`
        flex-direction: column;


        > * {
            flex: 1 0 100%;
            min-height: 200px;
            margin-left: 0 !important;

            &:first-child {
                margin-bottom: 24px;
            }
        }
    `}
`

const LightboxImage = styled.div`
    position: relative;
    margin-left: 20px;
    height: 194px;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;

    ${Image} {
        height: 100%;
    }
`

const FullScreenIcon = styled.div`
    background-image: url(${fullscreenIcon});
	${bgIcon}
    width: 18px;
    height: 14px;

    position: absolute;
    bottom: 7px;
    right: 7px;

    &:hover{
        cursor: pointer;
    }
`

// Modal Image

const Close = styled.div`
    background-image: url(${require('../assets/images/close-icon-32px-white.png')});
    ${bgImage};
    width: 25px;
    height: 25px;

    position: absolute;
    top: 47px;
    right: 46px;
    z-index: 12;
    cursor: pointer;
    ${hoverState};

    ${media.tablet`
        top: 24px;
        right: 24px;
    `}
`


const ModalImage = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.8);
    z-index: 12;
    display: flex;

    ${Close} {
        z-index: 13;
    }

    ${Image} {
        align-self: center;
        margin: 0 auto;
        width: calc(100vw - 80px);
        height: calc(100vh);
        ${bgIcon}
    }
`

export default Info

export const pageQuery = graphql`
	query InfoPageBySlug($slug: String!) {
		wordpressInfopages(slug: { eq: $slug }) {
            title
            slug
            acf_json
        }

        wordpressOptions {
			acf_json	
		} 
    }   
`
